import { ref } from "vue";
import { SymbolKind } from "vscode-languageserver-types";

/**
 * 防抖函数
 * @param cb
 * @param delay
 * @param immediate
 * @returns
 */
export const debounce = (cb: Function, delay: number = 500, immediate: boolean = false) => {
	let timer: ReturnType<typeof setTimeout> | null = null;
	const flag = ref(immediate);
	return function (...args: any[]) {
		clearTimeout(timer as ReturnType<typeof setTimeout>);
		if (flag.value && !timer) {
			cb(...args);
			flag.value = false;
		}
		timer = setTimeout(() => {
			if (!flag.value) {
				cb(...args);
			}
			timer = null;
		}, delay);
	};
};

/*
 * 节流
 * 先开启一个定时任务执行，定时任务完成后则清空，当再调用时，如果定时任务仍存在则不执行任何操作
 * */

export const throttle = (cb: Function, delay: number = 500) => {
	let timer: ReturnType<typeof setTimeout> | null = null;
	return function (...args: any[]) {
		if (!timer) {
			timer = setTimeout(() => {
				cb(...args);
				timer = null;
			}, delay);
		}
	};
};

// 过滤分类和搜索列表参数
export const filterParameters = (str: string): boolean => {
	if (
		str !== "count" &&
		str !== "page" &&
		str !== "sort_order" &&
		str !== "keyword" &&
		str !== "settab" &&
		str !== "gclid" &&
		str !== "category" &&
		str !== "categories_id" &&
		str !== "_gl" &&
		!str.includes("utm_") &&
		!str.includes("c_")
	) {
		return true;
	} else {
		return false;
	}
};

export const strSplit = (str: string): string[] => {
	return str.split(" ");
};

type IObject = {
	[key: string]: string | number | boolean;
};
export const objectToQueryString = (obj: IObject): string => {
	const arr = Object.keys(obj).map((key: any) => {
		return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
	});
	return arr.join("&");
};

export const getElementTop = (element: HTMLElement) => {
	let actualTop = element.offsetTop;
	let current = element.offsetParent as HTMLElement;
	while (current !== null) {
		actualTop += current.offsetTop;
		current = current.offsetParent as HTMLElement;
	}
	return actualTop;
};

export function addClass(el: HTMLElement, className: string) {
	// 如果当前元素样式列表中没有className
	if (!el.classList.contains(className)) {
		el.classList.add(className);
	}
}

export function removeClass(el: HTMLElement, className: string) {
	el.classList.remove(className);
}

/**
 * 自定义数组
 * 把数组重组为二维数组
 * chunkSize为每组的个数, 值小于1时一组展示
 * ReorganizeArrays([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], -1) // [[1,2,3,4,5,6,7,8,9,10]]
 * ReorganizeArrays([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 3)  // [[1,2,3],[4,5,6],[7,8,9],[10]]
 */
export const ReorganizeArrays = (array: any, chunkSize: number) => {
	const result = [];
	let start = 0;
	if (chunkSize <= 0) {
		result.push(array);
	} else {
		while (start < array.length) {
			result.push(array.slice(start, start + chunkSize));
			start += chunkSize;
		}
	}
	return result;
};
// 检查类型
export const checkType = (data: any) => {
	return Object.prototype.toString.call(data).slice(8, -1);
};

// 深拷贝
export const deepClone = (target: any) => {
	const targetType = checkType(target);
	let result: any;

	if (targetType === "Object") {
		result = {};
	} else if (targetType === "Array") {
		result = [];
	} else {
		return target;
	}

	for (const key in target) {
		const value = target[key];
		const valueType = checkType(value);
		if (valueType === "Object" || valueType === "Array") {
			result[key] = deepClone(value);
		} else {
			result[key] = value;
		}
	}
	return result;
};

export const preloadImages = (urls: string[]) => {
	const images = [];
	for (const url of urls) {
		const img = new Image();
		img.src = url;
		images.push(img);
	}
};

export const qs = {
	stringify(params: any) {
		const queryString = [];
		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				const value = params[key];

				if (Array.isArray(value)) {
					// 如果值是数组，则为每个值生成键值对
					value.forEach(val => {
						queryString.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
					});
				} else {
					// 否则，直接生成键值对
					queryString.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
				}
			}
		}

		return queryString.join("&");
	},
	parse(queryString: string): Record<string, string> {
		const obj: Record<string, string> = {};
		const pairs = queryString.split("&");

		for (const pair of pairs) {
			const [key, value] = pair.split("=");
			if (key) {
				obj[decodeURIComponent(key)] = decodeURIComponent(value || "");
			}
		}
		return obj;
	}
};

export const downloadFile = (fileUrl: string | URL | Request, fileName: string) => {
	fetch(fileUrl)
		.then(response => {
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			return response.blob();
		})
		.then(blob => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.style.display = "none";
			a.href = url;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		})
		.catch(e => {
			console.error("Download error:", e);
		});
};
/**
 * 模拟a标签点击下载
 * @param {string} url
 * @param {string} fileName
 * @param {string} target
 */
export const simulateClick = (url: string, fileName: string, target: string) => {
	const a = document.createElement("a");
	a.style.display = "none";
	a.href = url;
	a.download = fileName;
	if (target) {
		a.target = target;
	}
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

/**
 * 下载文件
 * @param {string} fileUrl
 * @param {string} fileName
 * @param {Function} errorCallback
 */
export const downloadDefaultFile = (fileUrl: string, fileName: string, errorCallback: Function) => {
	try {
		const xhr = new XMLHttpRequest();
		xhr.open("get", fileUrl, true);
		xhr.responseType = "blob";
		xhr.send();
		xhr.onload = function () {
			if (this.status === 200 || this.status === 304) {
				if ("msSaveOrOpenBlob" in navigator) {
					navigator.msSaveOrOpenBlob(this.response, fileName);
					return;
				}
				const blob = new Blob([this.response], {
					type: xhr.getResponseHeader("Content-Type")
				});
				const url = URL.createObjectURL(blob);
				if (fileName.split(".").length >= 2) {
					// 对于文件名以.结尾的会导致识别为扩展名
					const ext = fileUrl.substring(fileUrl.lastIndexOf("."));
					fileName = fileName + ext;
				}
				simulateClick(url, fileName, "_blank");
				URL.revokeObjectURL(url);
			}
		};
		xhr.onerror = function () {
			errorCallback && errorCallback();
		};
	} catch (error) {
		errorCallback && errorCallback();
	}
};
// 关键字高亮
export const highLight = (txt: string, keywords: string = "") => {
	// 匹配关键字正则
	if (keywords && txt.includes(keywords)) {
		const replaceReg = new RegExp(keywords, "g");
		// 高亮替换v-html值
		const replaceString = `<span class="highlight">${keywords}</span>`;
		// 开始替换
		txt = txt.replace(replaceReg, replaceString);
	}

	return txt;
};
// 全角转半角
export const checkIssbccase = (str: string) => {
	let result = "";
	for (let i = 0; i < str.length; i++) {
		const code = str.charCodeAt(i);
		if (code >= 65281 && code <= 65374) {
			result += String.fromCharCode(str.charCodeAt(i) - 65248);
		} else if (code == 12288) {
			result += String.fromCharCode(str.charCodeAt(i) - 12288 + 32);
		} else {
			result += str.charAt(i);
		}
	}
	return result;
};
