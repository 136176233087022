import routerOptions0 from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_yt5crs3ynwtv7ryxqoxydsq4dq/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}