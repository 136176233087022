import { default as indexMpFM3hQYypMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue?macro=true";
import { default as indexuOR2t82P5TMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue?macro=true";
import { default as CategoryaXK4d955q1Meta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue?macro=true";
import { default as CaseStudy8rMvwKy01OMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue?macro=true";
import { default as CaseStudyList32uA564C2eMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue?macro=true";
import { default as indexm7vDopI1eSMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue?macro=true";
import { default as indexPTjNyDJe71Meta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue?macro=true";
import { default as indexL2Q9Q2f2KLMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue?macro=true";
import { default as TestSpecialsYulgczpRfBMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue?macro=true";
import { default as CheckoutmQGNWSqzwZMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue?macro=true";
import { default as CheckoutAgainstlCANi5iM39Meta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue?macro=true";
import { default as ConfirmOrderXN16r8emTpMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue?macro=true";
import { default as indexoLWrnbwuaNMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue?macro=true";
import { default as DemofZUbIVce2GMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue?macro=true";
import { default as EthernetCableGuidets2iYoOO5wMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue?macro=true";
import { default as ServiceaROtM0nzAqMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue?macro=true";
import { default as PicosSoftwareayDYK2mGc1Meta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue?macro=true";
import { default as ContactSalesMail8ZlqR2uiUgMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue?macro=true";
import { default as HowToBuyB0NHmid47jMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue?macro=true";
import { default as ProductSupportqxvcmdAhMtMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue?macro=true";
import { default as IndexGazOsPsp3eMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue?macro=true";
import { default as TechnicalDocuments3lS7epLwLTMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue?macro=true";
import { default as SpecialsFmN5ebEM35Meta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue?macro=true";
import { default as BlogqlV0gseo7PMeta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue?macro=true";
import { default as CreateNewCaseoTZm6bWlR3Meta } from "/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue?macro=true";
export default [
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___au",
    path: indexMpFM3hQYypMeta?.path ?? "/au",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___cn",
    path: indexMpFM3hQYypMeta?.path ?? "/cn",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___de",
    path: indexMpFM3hQYypMeta?.path ?? "/de",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___de-en",
    path: indexMpFM3hQYypMeta?.path ?? "/de-en",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___en",
    path: indexMpFM3hQYypMeta?.path ?? "/",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___es",
    path: indexMpFM3hQYypMeta?.path ?? "/es",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___mx",
    path: indexMpFM3hQYypMeta?.path ?? "/mx",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___fr",
    path: indexMpFM3hQYypMeta?.path ?? "/fr",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___it",
    path: indexMpFM3hQYypMeta?.path ?? "/it",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___jp",
    path: indexMpFM3hQYypMeta?.path ?? "/jp",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___ru",
    path: indexMpFM3hQYypMeta?.path ?? "/ru",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___sg",
    path: indexMpFM3hQYypMeta?.path ?? "/sg",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___uk",
    path: indexMpFM3hQYypMeta?.path ?? "/uk",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___hk",
    path: indexMpFM3hQYypMeta?.path ?? "/hk",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___tw",
    path: indexMpFM3hQYypMeta?.path ?? "/tw",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpFM3hQYypMeta?.name ?? "home___mo",
    path: indexMpFM3hQYypMeta?.path ?? "/mo",
    meta: indexMpFM3hQYypMeta || {},
    alias: indexMpFM3hQYypMeta?.alias || [],
    redirect: indexMpFM3hQYypMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___au",
    path: indexuOR2t82P5TMeta?.path ?? "/au/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___cn",
    path: indexuOR2t82P5TMeta?.path ?? "/cn/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___de",
    path: indexuOR2t82P5TMeta?.path ?? "/de/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___de-en",
    path: indexuOR2t82P5TMeta?.path ?? "/de-en/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___en",
    path: indexuOR2t82P5TMeta?.path ?? "/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___es",
    path: indexuOR2t82P5TMeta?.path ?? "/es/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___mx",
    path: indexuOR2t82P5TMeta?.path ?? "/mx/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___fr",
    path: indexuOR2t82P5TMeta?.path ?? "/fr/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___it",
    path: indexuOR2t82P5TMeta?.path ?? "/it/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___jp",
    path: indexuOR2t82P5TMeta?.path ?? "/jp/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___ru",
    path: indexuOR2t82P5TMeta?.path ?? "/ru/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___sg",
    path: indexuOR2t82P5TMeta?.path ?? "/sg/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___uk",
    path: indexuOR2t82P5TMeta?.path ?? "/uk/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___hk",
    path: indexuOR2t82P5TMeta?.path ?? "/hk/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___tw",
    path: indexuOR2t82P5TMeta?.path ?? "/tw/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOR2t82P5TMeta?.name ?? "index_demo___mo",
    path: indexuOR2t82P5TMeta?.path ?? "/mo/index_demo",
    meta: indexuOR2t82P5TMeta || {},
    alias: indexuOR2t82P5TMeta?.alias || [],
    redirect: indexuOR2t82P5TMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___au",
    path: CategoryaXK4d955q1Meta?.path ?? "/au/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___cn",
    path: CategoryaXK4d955q1Meta?.path ?? "/cn/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___de",
    path: CategoryaXK4d955q1Meta?.path ?? "/de/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___de-en",
    path: CategoryaXK4d955q1Meta?.path ?? "/de-en/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___en",
    path: CategoryaXK4d955q1Meta?.path ?? "/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___es",
    path: CategoryaXK4d955q1Meta?.path ?? "/es/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___mx",
    path: CategoryaXK4d955q1Meta?.path ?? "/mx/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___fr",
    path: CategoryaXK4d955q1Meta?.path ?? "/fr/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___it",
    path: CategoryaXK4d955q1Meta?.path ?? "/it/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___jp",
    path: CategoryaXK4d955q1Meta?.path ?? "/jp/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___ru",
    path: CategoryaXK4d955q1Meta?.path ?? "/ru/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___sg",
    path: CategoryaXK4d955q1Meta?.path ?? "/sg/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___uk",
    path: CategoryaXK4d955q1Meta?.path ?? "/uk/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___hk",
    path: CategoryaXK4d955q1Meta?.path ?? "/hk/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___tw",
    path: CategoryaXK4d955q1Meta?.path ?? "/tw/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryaXK4d955q1Meta?.name ?? "category___mo",
    path: CategoryaXK4d955q1Meta?.path ?? "/mo/c/:id",
    meta: CategoryaXK4d955q1Meta || {},
    alias: CategoryaXK4d955q1Meta?.alias || [],
    redirect: CategoryaXK4d955q1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___au",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/au/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___cn",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/cn/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___de",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/de/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___de-en",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/de-en/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___en",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___es",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/es/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___mx",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/mx/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___fr",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/fr/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___it",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/it/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___jp",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/jp/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___ru",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/ru/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___sg",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/sg/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___uk",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/uk/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___hk",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/hk/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___tw",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/tw/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudy8rMvwKy01OMeta?.name ?? "case-study___mo",
    path: CaseStudy8rMvwKy01OMeta?.path ?? "/mo/case-study/:id.html",
    meta: CaseStudy8rMvwKy01OMeta || {},
    alias: CaseStudy8rMvwKy01OMeta?.alias || [],
    redirect: CaseStudy8rMvwKy01OMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___au",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/au/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___cn",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/cn/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___de",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/de/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___de-en",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/de-en/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___en",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___es",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/es/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___mx",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/mx/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___fr",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/fr/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___it",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/it/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___jp",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/jp/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___ru",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/ru/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___sg",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/sg/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___uk",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/uk/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___hk",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/hk/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___tw",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/tw/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyList32uA564C2eMeta?.name ?? "case-study-list___mo",
    path: CaseStudyList32uA564C2eMeta?.path ?? "/mo/case-study.html",
    meta: CaseStudyList32uA564C2eMeta || {},
    alias: CaseStudyList32uA564C2eMeta?.alias || [],
    redirect: CaseStudyList32uA564C2eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___au",
    path: indexm7vDopI1eSMeta?.path ?? "/au/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___cn",
    path: indexm7vDopI1eSMeta?.path ?? "/cn/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___de",
    path: indexm7vDopI1eSMeta?.path ?? "/de/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___de-en",
    path: indexm7vDopI1eSMeta?.path ?? "/de-en/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___en",
    path: indexm7vDopI1eSMeta?.path ?? "/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___es",
    path: indexm7vDopI1eSMeta?.path ?? "/es/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___mx",
    path: indexm7vDopI1eSMeta?.path ?? "/mx/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___fr",
    path: indexm7vDopI1eSMeta?.path ?? "/fr/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___it",
    path: indexm7vDopI1eSMeta?.path ?? "/it/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___jp",
    path: indexm7vDopI1eSMeta?.path ?? "/jp/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___ru",
    path: indexm7vDopI1eSMeta?.path ?? "/ru/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___sg",
    path: indexm7vDopI1eSMeta?.path ?? "/sg/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___uk",
    path: indexm7vDopI1eSMeta?.path ?? "/uk/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___hk",
    path: indexm7vDopI1eSMeta?.path ?? "/hk/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___tw",
    path: indexm7vDopI1eSMeta?.path ?? "/tw/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexm7vDopI1eSMeta?.name ?? "testCom___mo",
    path: indexm7vDopI1eSMeta?.path ?? "/mo/testCom",
    meta: indexm7vDopI1eSMeta || {},
    alias: indexm7vDopI1eSMeta?.alias || [],
    redirect: indexm7vDopI1eSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___au",
    path: indexPTjNyDJe71Meta?.path ?? "/au/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___cn",
    path: indexPTjNyDJe71Meta?.path ?? "/cn/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___de",
    path: indexPTjNyDJe71Meta?.path ?? "/de/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___de-en",
    path: indexPTjNyDJe71Meta?.path ?? "/de-en/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___en",
    path: indexPTjNyDJe71Meta?.path ?? "/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___es",
    path: indexPTjNyDJe71Meta?.path ?? "/es/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___mx",
    path: indexPTjNyDJe71Meta?.path ?? "/mx/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___fr",
    path: indexPTjNyDJe71Meta?.path ?? "/fr/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___it",
    path: indexPTjNyDJe71Meta?.path ?? "/it/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___jp",
    path: indexPTjNyDJe71Meta?.path ?? "/jp/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___ru",
    path: indexPTjNyDJe71Meta?.path ?? "/ru/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___sg",
    path: indexPTjNyDJe71Meta?.path ?? "/sg/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___uk",
    path: indexPTjNyDJe71Meta?.path ?? "/uk/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___hk",
    path: indexPTjNyDJe71Meta?.path ?? "/hk/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___tw",
    path: indexPTjNyDJe71Meta?.path ?? "/tw/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexPTjNyDJe71Meta?.name ?? "share_url___mo",
    path: indexPTjNyDJe71Meta?.path ?? "/mo/share_url/:code/:id",
    meta: indexPTjNyDJe71Meta || {},
    alias: indexPTjNyDJe71Meta?.alias || [],
    redirect: indexPTjNyDJe71Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___au",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/au/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___cn",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/cn/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___de",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/de/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___de-en",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/de-en/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___en",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___es",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/es/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___mx",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/mx/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___fr",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/fr/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___it",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/it/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___jp",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/jp/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___ru",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/ru/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___sg",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/sg/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___uk",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/uk/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___hk",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/hk/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___tw",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/tw/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2Q9Q2f2KLMeta?.name ?? "compare___mo",
    path: indexL2Q9Q2f2KLMeta?.path ?? "/mo/compare",
    meta: indexL2Q9Q2f2KLMeta || {},
    alias: indexL2Q9Q2f2KLMeta?.alias || [],
    redirect: indexL2Q9Q2f2KLMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___au",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/au/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___cn",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/cn/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___de",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/de/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___de-en",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/de-en/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___en",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___es",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/es/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___mx",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/mx/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___fr",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/fr/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___it",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/it/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___jp",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/jp/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___ru",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/ru/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___sg",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/sg/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___uk",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/uk/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___hk",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/hk/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___tw",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/tw/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecialsYulgczpRfBMeta?.name ?? "testSpecials___mo",
    path: TestSpecialsYulgczpRfBMeta?.path ?? "/mo/testSpecials",
    meta: { ...(TestSpecialsYulgczpRfBMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecialsYulgczpRfBMeta?.alias || [],
    redirect: TestSpecialsYulgczpRfBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___au",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/au/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___cn",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/cn/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___de",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/de/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___de-en",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/de-en/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___en",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___es",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/es/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___mx",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/mx/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___fr",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/fr/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___it",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/it/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___jp",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/jp/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___ru",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/ru/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___sg",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/sg/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___uk",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/uk/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___hk",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/hk/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___tw",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/tw/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutmQGNWSqzwZMeta?.name ?? "checkout___mo",
    path: CheckoutmQGNWSqzwZMeta?.path ?? "/mo/checkout",
    meta: { ...(CheckoutmQGNWSqzwZMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutmQGNWSqzwZMeta?.alias || [],
    redirect: CheckoutmQGNWSqzwZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___au",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/au/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___cn",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/cn/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___de",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/de/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___de-en",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/de-en/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___en",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___es",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/es/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___mx",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/mx/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___fr",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/fr/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___it",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/it/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___jp",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/jp/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___ru",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/ru/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___sg",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/sg/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___uk",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/uk/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___hk",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/hk/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___tw",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/tw/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstlCANi5iM39Meta?.name ?? "checkout-against___mo",
    path: CheckoutAgainstlCANi5iM39Meta?.path ?? "/mo/checkout-against",
    meta: { ...(CheckoutAgainstlCANi5iM39Meta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstlCANi5iM39Meta?.alias || [],
    redirect: CheckoutAgainstlCANi5iM39Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___au",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/au/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___cn",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/cn/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___de",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/de/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___de-en",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/de-en/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___en",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___es",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/es/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___mx",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/mx/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___fr",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/fr/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___it",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/it/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___jp",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/jp/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___ru",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/ru/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___sg",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/sg/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___uk",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/uk/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___hk",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/hk/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___tw",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/tw/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: ConfirmOrderXN16r8emTpMeta?.name ?? "confirm-order___mo",
    path: ConfirmOrderXN16r8emTpMeta?.path ?? "/mo/confirm-order",
    meta: { ...(ConfirmOrderXN16r8emTpMeta || {}), ...{"group":"Confirm Order Page"} },
    alias: ConfirmOrderXN16r8emTpMeta?.alias || [],
    redirect: ConfirmOrderXN16r8emTpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/ConfirmOrder/ConfirmOrder.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___au",
    path: indexoLWrnbwuaNMeta?.path ?? "/au/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___cn",
    path: indexoLWrnbwuaNMeta?.path ?? "/cn/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___de",
    path: indexoLWrnbwuaNMeta?.path ?? "/de/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___de-en",
    path: indexoLWrnbwuaNMeta?.path ?? "/de-en/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___en",
    path: indexoLWrnbwuaNMeta?.path ?? "/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___es",
    path: indexoLWrnbwuaNMeta?.path ?? "/es/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___mx",
    path: indexoLWrnbwuaNMeta?.path ?? "/mx/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___fr",
    path: indexoLWrnbwuaNMeta?.path ?? "/fr/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___it",
    path: indexoLWrnbwuaNMeta?.path ?? "/it/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___jp",
    path: indexoLWrnbwuaNMeta?.path ?? "/jp/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___ru",
    path: indexoLWrnbwuaNMeta?.path ?? "/ru/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___sg",
    path: indexoLWrnbwuaNMeta?.path ?? "/sg/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___uk",
    path: indexoLWrnbwuaNMeta?.path ?? "/uk/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___hk",
    path: indexoLWrnbwuaNMeta?.path ?? "/hk/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___tw",
    path: indexoLWrnbwuaNMeta?.path ?? "/tw/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLWrnbwuaNMeta?.name ?? "tool___mo",
    path: indexoLWrnbwuaNMeta?.path ?? "/mo/tool/:id",
    meta: indexoLWrnbwuaNMeta || {},
    alias: indexoLWrnbwuaNMeta?.alias || [],
    redirect: indexoLWrnbwuaNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___au",
    path: DemofZUbIVce2GMeta?.path ?? "/au/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___cn",
    path: DemofZUbIVce2GMeta?.path ?? "/cn/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___de",
    path: DemofZUbIVce2GMeta?.path ?? "/de/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___de-en",
    path: DemofZUbIVce2GMeta?.path ?? "/de-en/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___en",
    path: DemofZUbIVce2GMeta?.path ?? "/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___es",
    path: DemofZUbIVce2GMeta?.path ?? "/es/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___mx",
    path: DemofZUbIVce2GMeta?.path ?? "/mx/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___fr",
    path: DemofZUbIVce2GMeta?.path ?? "/fr/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___it",
    path: DemofZUbIVce2GMeta?.path ?? "/it/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___jp",
    path: DemofZUbIVce2GMeta?.path ?? "/jp/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___ru",
    path: DemofZUbIVce2GMeta?.path ?? "/ru/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___sg",
    path: DemofZUbIVce2GMeta?.path ?? "/sg/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___uk",
    path: DemofZUbIVce2GMeta?.path ?? "/uk/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___hk",
    path: DemofZUbIVce2GMeta?.path ?? "/hk/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___tw",
    path: DemofZUbIVce2GMeta?.path ?? "/tw/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemofZUbIVce2GMeta?.name ?? "demo___mo",
    path: DemofZUbIVce2GMeta?.path ?? "/mo/demo",
    meta: DemofZUbIVce2GMeta || {},
    alias: DemofZUbIVce2GMeta?.alias || [],
    redirect: DemofZUbIVce2GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___au",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/au/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___cn",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/cn/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___de",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/de/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___de-en",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/de-en/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___en",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___es",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/es/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___mx",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/mx/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___fr",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/fr/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___it",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/it/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___jp",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/jp/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___ru",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/ru/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___sg",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/sg/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___uk",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/uk/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___hk",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/hk/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___tw",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/tw/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuidets2iYoOO5wMeta?.name ?? "ethernet-cable-guide___mo",
    path: EthernetCableGuidets2iYoOO5wMeta?.path ?? "/mo/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuidets2iYoOO5wMeta || {},
    alias: EthernetCableGuidets2iYoOO5wMeta?.alias || [],
    redirect: EthernetCableGuidets2iYoOO5wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___au",
    path: ServiceaROtM0nzAqMeta?.path ?? "/au/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___cn",
    path: ServiceaROtM0nzAqMeta?.path ?? "/cn/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___de",
    path: ServiceaROtM0nzAqMeta?.path ?? "/de/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___de-en",
    path: ServiceaROtM0nzAqMeta?.path ?? "/de-en/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___en",
    path: ServiceaROtM0nzAqMeta?.path ?? "/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___es",
    path: ServiceaROtM0nzAqMeta?.path ?? "/es/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___mx",
    path: ServiceaROtM0nzAqMeta?.path ?? "/mx/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___fr",
    path: ServiceaROtM0nzAqMeta?.path ?? "/fr/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___it",
    path: ServiceaROtM0nzAqMeta?.path ?? "/it/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___jp",
    path: ServiceaROtM0nzAqMeta?.path ?? "/jp/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___ru",
    path: ServiceaROtM0nzAqMeta?.path ?? "/ru/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___sg",
    path: ServiceaROtM0nzAqMeta?.path ?? "/sg/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___uk",
    path: ServiceaROtM0nzAqMeta?.path ?? "/uk/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___hk",
    path: ServiceaROtM0nzAqMeta?.path ?? "/hk/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___tw",
    path: ServiceaROtM0nzAqMeta?.path ?? "/tw/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceaROtM0nzAqMeta?.name ?? "customer-services___mo",
    path: ServiceaROtM0nzAqMeta?.path ?? "/mo/fscare-customer-services.html",
    meta: ServiceaROtM0nzAqMeta || {},
    alias: ServiceaROtM0nzAqMeta?.alias || [],
    redirect: ServiceaROtM0nzAqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___au",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/au/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___cn",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/cn/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___de",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/de/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___de-en",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/de-en/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___en",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___es",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/es/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___mx",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/mx/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___fr",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/fr/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___it",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/it/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___jp",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/jp/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___ru",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/ru/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___sg",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/sg/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___uk",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/uk/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___hk",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/hk/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___tw",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/tw/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareayDYK2mGc1Meta?.name ?? "software-customized___mo",
    path: PicosSoftwareayDYK2mGc1Meta?.path ?? "/mo/picos-software-customized.html",
    meta: PicosSoftwareayDYK2mGc1Meta || {},
    alias: PicosSoftwareayDYK2mGc1Meta?.alias || [],
    redirect: PicosSoftwareayDYK2mGc1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___au",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/au/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___cn",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/cn/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___de",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/de/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___de-en",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/de-en/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___en",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___es",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/es/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___mx",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/mx/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___fr",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/fr/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___it",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/it/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___jp",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/jp/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___ru",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/ru/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___sg",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/sg/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___uk",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/uk/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___hk",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/hk/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___tw",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/tw/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMail8ZlqR2uiUgMeta?.name ?? "contact_sales_mail___mo",
    path: ContactSalesMail8ZlqR2uiUgMeta?.path ?? "/mo/contact_sales_mail.html",
    meta: ContactSalesMail8ZlqR2uiUgMeta || {},
    alias: ContactSalesMail8ZlqR2uiUgMeta?.alias || [],
    redirect: ContactSalesMail8ZlqR2uiUgMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___au",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/au/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___cn",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/cn/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___de",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/de/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___de-en",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/de-en/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___en",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___es",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/es/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___mx",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/mx/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___fr",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/fr/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___it",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/it/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___jp",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/jp/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___ru",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/ru/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___sg",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/sg/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___uk",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/uk/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___hk",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/hk/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___tw",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/tw/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyB0NHmid47jMeta?.name ?? "how-to-buy___mo",
    path: HowToBuyB0NHmid47jMeta?.path ?? "/mo/how-to-buy.html",
    meta: HowToBuyB0NHmid47jMeta || {},
    alias: HowToBuyB0NHmid47jMeta?.alias || [],
    redirect: HowToBuyB0NHmid47jMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___au",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/au/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___cn",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/cn/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___de",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/de/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___de-en",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/de-en/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___en",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___es",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/es/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___mx",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/mx/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___fr",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/fr/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___it",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/it/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___jp",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/jp/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___ru",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/ru/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___sg",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/sg/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___uk",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/uk/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___hk",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/hk/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___tw",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/tw/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupportqxvcmdAhMtMeta?.name ?? "products-support___mo",
    path: ProductSupportqxvcmdAhMtMeta?.path ?? "/mo/products_support.html",
    meta: ProductSupportqxvcmdAhMtMeta || {},
    alias: ProductSupportqxvcmdAhMtMeta?.alias || [],
    redirect: ProductSupportqxvcmdAhMtMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___au",
    path: IndexGazOsPsp3eMeta?.path ?? "/au/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___cn",
    path: IndexGazOsPsp3eMeta?.path ?? "/cn/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___de",
    path: IndexGazOsPsp3eMeta?.path ?? "/de/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___de-en",
    path: IndexGazOsPsp3eMeta?.path ?? "/de-en/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___en",
    path: IndexGazOsPsp3eMeta?.path ?? "/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___es",
    path: IndexGazOsPsp3eMeta?.path ?? "/es/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___mx",
    path: IndexGazOsPsp3eMeta?.path ?? "/mx/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___fr",
    path: IndexGazOsPsp3eMeta?.path ?? "/fr/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___it",
    path: IndexGazOsPsp3eMeta?.path ?? "/it/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___jp",
    path: IndexGazOsPsp3eMeta?.path ?? "/jp/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___ru",
    path: IndexGazOsPsp3eMeta?.path ?? "/ru/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___sg",
    path: IndexGazOsPsp3eMeta?.path ?? "/sg/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___uk",
    path: IndexGazOsPsp3eMeta?.path ?? "/uk/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___hk",
    path: IndexGazOsPsp3eMeta?.path ?? "/hk/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___tw",
    path: IndexGazOsPsp3eMeta?.path ?? "/tw/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexGazOsPsp3eMeta?.name ?? "preview_sheet___mo",
    path: IndexGazOsPsp3eMeta?.path ?? "/mo/products_support/preview_sheet.html",
    meta: IndexGazOsPsp3eMeta || {},
    alias: IndexGazOsPsp3eMeta?.alias || [],
    redirect: IndexGazOsPsp3eMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___au",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/au/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___cn",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/cn/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___de",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/de/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___de-en",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/de-en/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___en",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___es",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/es/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___mx",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/mx/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___fr",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/fr/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___it",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/it/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___jp",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/jp/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___ru",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/ru/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___sg",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/sg/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___uk",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/uk/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___hk",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/hk/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___tw",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/tw/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocuments3lS7epLwLTMeta?.name ?? "technical_documents___mo",
    path: TechnicalDocuments3lS7epLwLTMeta?.path ?? "/mo/technical_documents.html",
    meta: TechnicalDocuments3lS7epLwLTMeta || {},
    alias: TechnicalDocuments3lS7epLwLTMeta?.alias || [],
    redirect: TechnicalDocuments3lS7epLwLTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    path: "/technical_documents/search.html",
    name: "technical_documents_search"
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___au",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/au/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___cn",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/cn/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___de",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/de/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___de-en",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/de-en/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___en",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___es",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/es/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___mx",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/mx/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___fr",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/fr/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___it",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/it/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___jp",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/jp/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___ru",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/ru/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___sg",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/sg/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___uk",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/uk/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___hk",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/hk/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___tw",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/tw/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "specials___mo",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/mo/specials/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___au",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/au/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___cn",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/cn/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___de",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/de/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___de-en",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/de-en/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___en",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___es",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/es/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___mx",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/mx/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___fr",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/fr/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___it",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/it/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___jp",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/jp/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___ru",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/ru/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___sg",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/sg/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___uk",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/uk/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___hk",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/hk/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___tw",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/tw/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsFmN5ebEM35Meta?.name ?? "supports___mo",
    path: SpecialsFmN5ebEM35Meta?.path ?? "/mo/support/:id.html",
    meta: { ...(SpecialsFmN5ebEM35Meta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsFmN5ebEM35Meta?.alias || [],
    redirect: SpecialsFmN5ebEM35Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___au",
    path: BlogqlV0gseo7PMeta?.path ?? "/au/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___cn",
    path: BlogqlV0gseo7PMeta?.path ?? "/cn/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___de",
    path: BlogqlV0gseo7PMeta?.path ?? "/de/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___de-en",
    path: BlogqlV0gseo7PMeta?.path ?? "/de-en/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___en",
    path: BlogqlV0gseo7PMeta?.path ?? "/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___es",
    path: BlogqlV0gseo7PMeta?.path ?? "/es/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___mx",
    path: BlogqlV0gseo7PMeta?.path ?? "/mx/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___fr",
    path: BlogqlV0gseo7PMeta?.path ?? "/fr/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___it",
    path: BlogqlV0gseo7PMeta?.path ?? "/it/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___jp",
    path: BlogqlV0gseo7PMeta?.path ?? "/jp/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___ru",
    path: BlogqlV0gseo7PMeta?.path ?? "/ru/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___sg",
    path: BlogqlV0gseo7PMeta?.path ?? "/sg/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___uk",
    path: BlogqlV0gseo7PMeta?.path ?? "/uk/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___hk",
    path: BlogqlV0gseo7PMeta?.path ?? "/hk/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___tw",
    path: BlogqlV0gseo7PMeta?.path ?? "/tw/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogqlV0gseo7PMeta?.name ?? "blog___mo",
    path: BlogqlV0gseo7PMeta?.path ?? "/mo/blog.html",
    meta: BlogqlV0gseo7PMeta || {},
    alias: BlogqlV0gseo7PMeta?.alias || [],
    redirect: BlogqlV0gseo7PMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___au",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/au/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___cn",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/cn/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___de",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/de/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___de-en",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/de-en/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___en",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___es",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/es/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___mx",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/mx/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___fr",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/fr/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___it",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/it/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___jp",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/jp/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___ru",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/ru/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___sg",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/sg/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___uk",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/uk/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___hk",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/hk/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___tw",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/tw/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCaseoTZm6bWlR3Meta?.name ?? "create_new_support___mo",
    path: CreateNewCaseoTZm6bWlR3Meta?.path ?? "/mo/create-new-support",
    meta: CreateNewCaseoTZm6bWlR3Meta || {},
    alias: CreateNewCaseoTZm6bWlR3Meta?.alias || [],
    redirect: CreateNewCaseoTZm6bWlR3Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static-pre front-category-pre/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  }
]