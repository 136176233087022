const fixScroll = (function (bodyClass) {
	let count = 0;
	let lockName = "";
	return {
		fixed: function (name?: string) {
			if (!lockName && name) {
				lockName = name;
			}
			if (count < 1) {
				document.body.classList.add(bodyClass);
			}
			count += 1;
		},
		unfixed: function (name?: string) {
			count -= 1;
			// 做一层锁，只有指定name的才能关掉
			if (lockName && lockName !== name) {
				return;
			}
			lockName = "";
			if (count < 1) {
				document.body.classList.remove(bodyClass);
				count = 0;
			}
		},
		reset: function () {
			count = 0;
			document.body.classList.remove(bodyClass);
		}
	};
})("fixScroll");

export default fixScroll;
